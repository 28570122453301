<template>
  <div>
    <div class="card-toolbar mb-5">
      <button v-if="$can('payments.create')" @click="showModal" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('payments.new_payment') }}
      </button>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="payment_methods">{{ $t('payments.payment_methods') }}</label>
              <input v-model="filters.name" type="text" id="payment_methods" class="form-control">
            </div>

            <div class="form-group col-md-6">
              <label for="is_active">{{ $t('status') }}</label>
              <select name="" id="is_active" v-model="filters.is_active" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
              </select>
            </div>
            <div class="form-group d-inline-flex col-md-6">
              <button type="button" @click="doFilter" class="btn btn-primary my-auto mb-0 mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{ $t('search') }}</span>
                                </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger my-auto mb-0 mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{ $t('reset_search') }}</span>
                                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">

      <div class="card-body">
        <div class="d-flex justify-content-end mb-5">
          <custom-export-data v-if="$can('payments.export_data')" :data-list="dataList" :file-name="fileName" :fields="json_fields"></custom-export-data>
        </div>
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="status" slot-scope="props">
            <b-form-checkbox v-if="$can('payments.change_status')"
                             size="lg" @change="changeStatus(props.row.id, props.row.is_active)"
                             v-model="props.row.is_active"
                             :name="'check-button'+props.row.id"
                             switch :key="props.row.id">
            </b-form-checkbox>
            <b-form-checkbox v-else
                             size="lg" :disabled="true"
                             v-model="props.row.is_active"
                             :name="'check-button'+props.row.id"
                             switch :key="props.row.id">
            </b-form-checkbox>

          </template>

          <template slot="actions" slot-scope="props">
            <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('payments.update')" @click="showModal(props.row)">mdi-pencil</v-icon>
            <!--            <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('payments.delete')" @click="deletepayment(props.row)">mdi-delete</v-icon>-->
            <br>
            <div>
              <b-dropdown right :text="$t('actions')" :id="'more'+props.row.id">
                <b-dropdown-item v-if="$can('payments.pay_tabs')" @click="showModalPay(props.row,'paytabs')">
                  {{ $t('payments.paytabs') }}
                </b-dropdown-item>
                <b-dropdown-item v-if="$can('payments.authorize_net')" @click="showModalPay(props.row,'authorize')">{{ $t('payments.authorize_net') }}</b-dropdown-item>
                <b-dropdown-item v-if="$can('payments.checkout')" @click="showModalPay(props.row,'checkout')">{{ $t('payments.checkout') }}</b-dropdown-item>
              </b-dropdown>
            </div>

          </template>
        </v-server-table>
        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->

    <b-modal ref="modal" hide-footer :title="$t('payments.payment')">
      <div class="row">
        <div class="col-md-6 mb-5">
          <label for="payment_method_name">
            {{ $t('payments.payment_methods') }}<span class="text-danger">*</span>
          </label>
          <input v-model="payment.payment_method_name" type="text" id="payment_method_name" class="form-control" :class="validation && validation.payment_method_name ? 'is-invalid' : ''">
          <span v-if="validation && validation.payment_method_name" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.payment_method_name[0] }}
                </span>
        </div>
        <div class="col-md-6 mb-5">
          <label>
            {{ $t('type') }}<span class="text-danger">*</span>
          </label>
          <!-- <input v-model="payment.cash" type="text" id="name" class="form-control" :class="validation && validation.cash ? 'is-invalid' : ''"> -->
          <select v-model="payment.cash" class="custom-select" :class="validation && validation.cash ? 'is-invalid' : ''">
            <option v-for="(cash, index) in cash_list" :value="cash.id" :key="index">
              {{ cash.title }}
            </option>
          </select>

          <span v-if="validation && validation.cash" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.cash[0] }}
                        </span>
        </div>
        <div class="col-md-6 mb-5">
          <label>
            {{ $t('payments.minimum_amount') }}
          </label>
          <input v-model="payment.minimum_amount" type="number" min="1.0" id="minimum_amount" class="form-control" :class="validation && validation.minimum_amount ? 'is-invalid' : ''">
          <span v-if="validation && validation.minimum_amount" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.minimum_amount[0] }}
                        </span>
        </div>

        <div class="col-md-6 mb-5">
          <label>
            {{ $t('payments.fees_rate') }}
          </label>
          <input v-model="payment.fees_rate" type="number" min="1.0" id="fees_rate" class="form-control" :class="validation && validation.fees_rate ? 'is-invalid' : ''">
          <span v-if="validation && validation.fees_rate" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.fees_rate[0] }}
                        </span>
        </div>
        <div class="col-md-6 mb-5">
          <label>
            {{ $t('payments.fees_fixed') }}
          </label>
          <input v-model="payment.fees_fixed" type="number" min="1.0" id="fees_fixed" class="form-control" :class="validation && validation.fees_fixed ? 'is-invalid' : ''">
          <span v-if="validation && validation.fees_fixed" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.fees_fixed[0] }}
                        </span>
        </div>
        <div class="col-md-6 mb-5">
          <label>{{ $t('payments.status') }}</label>
          <b-form-checkbox size="lg" v-model="payment.is_active" name="check-button" switch></b-form-checkbox>
        </div>
        <div class="col-md-6 mb-5">
          <label>{{ $t('payments.active_for_online_clients') }}:</label>
          <b-form-checkbox size="lg" v-model="payment.active_for_online_clients" name="check-button" switch></b-form-checkbox>
        </div>
        <div class="col-md-6 mb-5">
          <label>{{ $t('payments.effect_type') }}</label>
          <b-form-checkbox size="lg" v-model="payment.effect_type" name="check-button" switch></b-form-checkbox>
        </div>

        <div class="col-md-12 mb-5">
          <label>
            {{ $t('notes') }}
          </label>
          <textarea v-model="payment.notes" class="form-control" style="height: 63px;"></textarea>
          <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.notes[0] }}
                </span>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{ $t('close') }}</b-button>
        <b-button class="mt-2 mr-1" variant="primary" @click="save">{{ $t('save') }}</b-button>
      </div>
    </b-modal>


    <!-- modal payments type -->

    <!-- <b-modal ref="modalPay" hide-footer :title="$t('payments.payment_type')"> -->
    <b-modal ref="modalPay" hide-footer :title="pay_type_title">

      <div class="row" v-if="this.pay_type == 'paytabs'">

        <div class="col-md-6 mb-5">
          <label for="email">
            {{ $t('email') }}
          </label>
          <input v-model="pay_details.email" type="text" id="email" class="form-control" required>
        </div>
        <div class="col-md-6 mb-5">
          <label for="key">
            {{ $t('key') }}
          </label>
          <input v-model="pay_details.key" type="text" id="key" class="form-control">
        </div>

        <div class="col-md-12 mb-5">
          <label>{{ $t('payments.verified') }}:</label>
          <b-form-checkbox size="lg" v-model="pay_details.is_verified" name="check-button" switch></b-form-checkbox>
        </div>
      </div>

      <div class="row" v-else-if="this.pay_type == 'authorize'">
        <div class="col-md-6 mb-5">
          <label for="api_login_id">
            {{ $t('payments.api_login_id') }}
          </label>
          <input v-model="pay_details.api_login_id" type="text" id="api_login_id" class="form-control">
        </div>
        <div class="col-md-6 mb-5">
          <label for="transaction_key">
            {{ $t('payments.transaction_key') }}
          </label>
          <input v-model="pay_details.transaction_key" type="text" id="transaction_key" class="form-control">
        </div>

        <div class="col-md-12 mb-5">
          <label>{{ $t('payments.verified') }}:</label>
          <b-form-checkbox size="lg" v-model="pay_details.is_verified" name="check-button" switch></b-form-checkbox>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-6 mb-5">
          <label for="account_number">
            {{ $t('payments.account_number') }}
          </label>
          <input v-model="pay_details.account_number" type="text" id="account_number" class="form-control">
        </div>
        <div class="col-md-6 mb-5">
          <label for="approved_url">
            {{ $t('payments.approved_url') }}
          </label>
          <input v-model="pay_details.approved_url" type="text" id="approved_url" class="form-control">
        </div>

        <div class="col-md-12 mb-5">
          <label>{{ $t('payments.verified') }}:</label>
          <b-form-checkbox size="lg" v-model="pay_details.is_verified" name="check-button" switch></b-form-checkbox>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{ $t('close') }}</b-button>
        <b-button class="mt-2 mr-1" variant="primary" @click="savePaymentDetails">{{ $t('save') }}</b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-payment",
  components: {},
  data() {
    return {
      mainRouteDependency: 'base/dependency',
      payment: {
        payment_method_name: '',
        cash: '',
        minimum_amount: '',
        active_for_online_clients: false,
        fees_rate: '',
        fees_fixed: '',
        is_active: true,
        effect_type: true,
      },
      pay_details: {
        id: '',
        pay_type: '',
        email: '',
        key: '',
        is_verified: false,
        api_login_id: '',
        transaction_key: '',
        account_number: '',
        approved_url: '',
      },
      showAdvancedSearch: false,
      filter: {
        sortBy: 'id',
      },
      filters: {
        payment_method_name: '',
        is_active: '',
      },
      columns: ['payment_method_name', 'cash_name', 'minimum_amount', 'fees_rate', 'fees_fixed', 'status', 'actions'],
      validation: null,
      mainRoute: 'settings/payments',
      routeChangeStatus: 'settings/payment/change-status',
      routePaymentDetails: 'settings/payment/payment-details',
      isEditing: false,
      id: null,
      pay_type: null,
      cash_list: [
        // {id: 1, name: this.$t('main')},
        // {id: 2, name: this.$t('others')},
      ],
      dataList: [],
      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],
      pay_type_title: null,
    }
  },
  computed: {

    json_fields: function () {
      let fields = {};
      fields[this.$t('payments.payment_methods')] = 'payment_method_name';
      fields[this.$t('payments.cash')] = 'cash_name';
      fields[this.$t('payments.minimum_amount')] = 'minimum_amount';
      fields[this.$t('payments.fees_rate')] = 'fees_rate';
      fields[this.$t('payments.fees_fixed')] = 'fees_fixed';
      fields[this.$t('payments.status')] = 'is_active';
      return fields;
    },
    fileName: function () {
      return this.$t('procedure.procedure');
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          payment_method_name: that.$t('payments.payment_methods'),
          cash_name: that.$t('payments.cash'),
          minimum_amount: that.$t('payments.minimum_amount'),
          active_for_online_clients: that.$t('payments.active_for_online_clients'),
          fees_rate: that.$t('payments.fees_rate'),
          fees_fixed: that.$t('payments.fees_fixed'),
          status: that.$t('payments.status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',
        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.payments_management")}]);
    this.getCashList();
  },
  methods: {
    changeStatus(id, status) {
      ApiService.patch(this.routeChangeStatus + '/' + id, {
        is_active: (status ? 1 : 0),
      }).then((response) => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
      });
    },

    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.payment_method_name = '';
      this.filters.is_active = false;

      this.$refs.table.refresh();
    },
    save() {
      if (this.isEditing) {
        this.updatepayment();
      } else {
        this.savepayment();
      }
    },

    savePaymentDetails() {
      ApiService.patch(`${this.routePaymentDetails}/${this.pay_details.id}`, {
        ...this.pay_details
      }).then((response) => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
        this.hideModal();
      }).catch((error) => {
        this.$errorAlert(error);
      });
    },
    getFetch() {
      this.$refs.table.refresh();
    },
    actionDelete(payment) {
      ApiService.delete(`${this.mainRoute}/${payment.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    deletepayment(payment) {
      this.$confirmAlert('', this.actionDelete, payment);
    },

    showModal(data) {
      if (!data.id) {
        this.reset();
        this.$refs['modal'].show()
      } else {
        this.isEditing = true;
        this.id = data.id;
        this.getData(data);
        this.$refs['modal'].show();
      }
    },

    showModalPay(data, type) {
      this.pay_details = {
        id: '',
        pay_type: '',
        email: '',
        key: '',
        is_verified: false,
        api_login_id: '',
        transaction_key: '',
        account_number: '',
        approved_url: '',
      };

      this.pay_type = type;
      switch (type) {
        case 'paytabs':
          this.pay_type_title = this.$t('payments.paytabs');
          break;
        case 'authorize':
          this.pay_type_title = this.$t('payments.authorize_net');
          break;
        case 'checkout':
          this.pay_type_title = this.$t('payments.checkout');
          break;
      }

      this.$refs['modalPay'].show();
      this.pay_details.pay_type = this.pay_type;
      this.pay_details.id = data.id;

      // console.log(this.pay_details);
      // console.log(this.payment_details);

      if (this.pay_details != null) {
        this.isEditing = true;
        if (data.paytabs) {
          this.pay_details.email = data.paytabs.email;
          this.pay_details.key = data.paytabs.key;
          this.pay_details.is_verified = data.paytabs.is_verified;
        }
        if (data.authorize) {
          this.pay_details.api_login_id = data.authorize.api_login_id;
          this.pay_details.transaction_key = data.authorize.transaction_key;
          this.pay_details.is_verified = data.authorize.is_verified;
        }
        if (data.checkout) {
          this.pay_details.account_number = data.checkout.account_number;
          this.pay_details.approved_url = data.checkout.approved_url;
          this.pay_details.is_verified = data.checkout.is_verified;
        }

      } else {
        this.isEditing = false;
      }
    },
    hideModal() {
      this.reset();
      this.$refs['modal'].hide();
      this.$refs['modalPay'].hide();
    },
    toggleModal() {
      this.$refs['modal'].toggle('#toggle-btn')
    },
    savepayment() {
      ApiService.post(this.mainRoute, this.payment).then((response) => {
        this.$successAlert(response.data.message);
        this.afterSavepayment();
      }).catch((errors) => {
        this.validation = errors.response ? errors.response.data.errors : null;
      });
    },
    updatepayment() {
      ApiService.put(`${this.mainRoute}/${this.id}`, {
        ...this.payment,
      }).then((response) => {
        this.$successAlert(response.data.message);
        this.validation = null;
        this.afterSavepayment();
      })
          .catch((errors) => {
            this.validation = errors.response ? errors.response.data.errors : null;
          });
    },
    getData(data) {
      this.payment.payment_method_name = data.payment_method_name;
      this.payment.minimum_amount = data.minimum_amount;
      this.payment.cash = data.cash;
      this.payment.fees_fixed = data.fees_fixed;
      this.payment.fees_rate = data.fees_rate;
      this.payment.is_active = data.is_active;
      this.payment.active_for_online_clients = data.active_for_online_clients;
      this.payment.notes = data.notes;
      this.payment.effect_type = data.effect_type;

    },

    reset() {
      this.payment = {
        payment_method_name: '',
        cash: '',
        minimum_amount: '',
        active_for_online_clients: false,
        fees_rate: '',
        fees_fixed: '',
        is_active: true,
        effect_type: true,
      };
      this.pay_details = {
        id: '',
        pay_type: '',
        email: '',
        key: '',
        is_verified: false,
        api_login_id: '',
        transaction_key: '',
        account_number: '',
        approved_url: '',
      };
      this.validation = [];
      this.isEditing = false;
    },

    afterSavepayment() {
      this.payment = {
        payment_method_name: '',
        cash: '',
        minimum_amount: '',
        active_for_online_clients: false,
        fees_rate: '',
        fees_fixed: '',
        is_active: true,
        effect_type: true,
      };
      this.hideModal();
      this.getFetch();
    },

    getCashList() {
      ApiService.get(this.mainRouteDependency + "/payment_setting_types").then((response) => {
        this.cash_list = response.data.data;
      });
    },
  },
};
</script>
